<template>
    <button class="add-btn">
        <!-- <img src="https://iv.okvu.cn/vugw/img/vuht.png" alt="" /> -->
        <vy-icon name="添加一级" scale="2.25" v-if="btnType == '一级'" />
        <vy-icon name="添加二级" scale="2.25" v-if="btnType == '二级'" />
        <vy-icon name="添加三级" scale="2.25" v-if="btnType == '三级'" />
        <vy-icon name="添加四级" scale="2.25" v-if="btnType == '四级'" />
    </button>
</template>

<script>
import VyIcon from "./VyIcon.vue";
export default {
    components: { VyIcon },
    props: {
        btnType: {
            type: String,
            default: "一级",
        },
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="less" scoped>
.add-btn {
    // width: 90px;
    width: 36px;
    height: 36px;
    border: none;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    // img {
    //     position: absolute;
    //     top: -130px;
    //     left: 1px;
    //     width: 650px;
    // }
}
</style>
